import React, {useEffect, useState, useRef} from "react";
import {useSelector} from "react-redux";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import { isBrowser, isMobile, isTablet } from 'react-device-detect';

import HomeBtnClose from "../images/gatepage/common/close_btn.png";

//HHT1
import HHT1ep01Card from "../images/gatepage/card/HHT1/ep_01.png";
import HHT1ep02Card from "../images/gatepage/card/HHT1/ep_02.png";
import HHT1ep03Card from "../images/gatepage/card/HHT1/ep_03.png";
import HHT1ep04Card from "../images/gatepage/card/HHT1/ep_04.png";
import HHT1ep05Card from "../images/gatepage/card/HHT1/ep_05.png";
import HHT1ep06Card from "../images/gatepage/card/HHT1/ep_06.png";
import HHT1ep07Card from "../images/gatepage/card/HHT1/ep_07.png";
import HHT1ep08Card from "../images/gatepage/card/HHT1/ep_08.png";
import HHT1ep09Card from "../images/gatepage/card/HHT1/ep_09.png";
import HHT1ep10Card from "../images/gatepage/card/HHT1/ep_10.png";
import HHT1ep11Card from "../images/gatepage/card/HHT1/ep_11.png";
import HHT1ep12Card from "../images/gatepage/card/HHT1/ep_12.png";
import HHT1ep13Card from "../images/gatepage/card/HHT1/ep_13.png";
import HHT1ep14Card from "../images/gatepage/card/HHT1/ep_14.png";
import HHT1ep15Card from "../images/gatepage/card/HHT1/ep_15.png";
import HHT1ep16Card from "../images/gatepage/card/HHT1/ep_16.png";
import HHT1ep17Card from "../images/gatepage/card/HHT1/ep_17.png";
import HHT1ep18Card from "../images/gatepage/card/HHT1/ep_18.png";
import HHT1ep19Card from "../images/gatepage/card/HHT1/ep_19.png";
import HHT1ep20Card from "../images/gatepage/card/HHT1/ep_20.png";

import HHT1ep01Code from "../images/gatepage/episodecode/HHT1/tit_ep01.png";
import HHT1ep02Code from "../images/gatepage/episodecode/HHT1/tit_ep02.png";
import HHT1ep03Code from "../images/gatepage/episodecode/HHT1/tit_ep03.png";
import HHT1ep04Code from "../images/gatepage/episodecode/HHT1/tit_ep04.png";
import HHT1ep05Code from "../images/gatepage/episodecode/HHT1/tit_ep05.png";
import HHT1ep06Code from "../images/gatepage/episodecode/HHT1/tit_ep06.png";
import HHT1ep07Code from "../images/gatepage/episodecode/HHT1/tit_ep07.png";
import HHT1ep08Code from "../images/gatepage/episodecode/HHT1/tit_ep08.png";
import HHT1ep09Code from "../images/gatepage/episodecode/HHT1/tit_ep09.png";
import HHT1ep10Code from "../images/gatepage/episodecode/HHT1/tit_ep10.png";
import HHT1ep11Code from "../images/gatepage/episodecode/HHT1/tit_ep11.png";
import HHT1ep12Code from "../images/gatepage/episodecode/HHT1/tit_ep12.png";
import HHT1ep13Code from "../images/gatepage/episodecode/HHT1/tit_ep13.png";
import HHT1ep14Code from "../images/gatepage/episodecode/HHT1/tit_ep14.png";
import HHT1ep15Code from "../images/gatepage/episodecode/HHT1/tit_ep15.png";
import HHT1ep16Code from "../images/gatepage/episodecode/HHT1/tit_ep16.png";
import HHT1ep17Code from "../images/gatepage/episodecode/HHT1/tit_ep17.png";
import HHT1ep18Code from "../images/gatepage/episodecode/HHT1/tit_ep18.png";
import HHT1ep19Code from "../images/gatepage/episodecode/HHT1/tit_ep19.png";
import HHT1ep20Code from "../images/gatepage/episodecode/HHT1/tit_ep20.png";

import logo1 from "../images/gatepage/common/img_logo.png";
import title1 from "../images/gatepage/common/img_title.png";
import charac01 from "../images/gatepage/common/person-charac01.png";
import charac02 from "../images/gatepage/common/person-charac02.png";

import leftButton from "../images/activity-button/btn_pre_d.png"
import rightButton from "../images/activity-button/btn_next_d.png"



// HHT2
import HHT2ep01Card from "../images/gatepage/card/HHT2/hht2_ep_01.png";
import HHT2ep02Card from "../images/gatepage/card/HHT2/hht2_ep_02.png";
import HHT2ep03Card from "../images/gatepage/card/HHT2/hht2_ep_03.png";
import HHT2ep04Card from "../images/gatepage/card/HHT2/hht2_ep_04.png";
import HHT2ep05Card from "../images/gatepage/card/HHT2/hht2_ep_05.png";
import HHT2ep06Card from "../images/gatepage/card/HHT2/hht2_ep_06.png";
import HHT2ep07Card from "../images/gatepage/card/HHT2/hht2_ep_07.png";
import HHT2ep08Card from "../images/gatepage/card/HHT2/hht2_ep_08.png";
import HHT2ep09Card from "../images/gatepage/card/HHT2/hht2_ep_09.png";
import HHT2ep10Card from "../images/gatepage/card/HHT2/hht2_ep_10.png";
import HHT2ep11Card from "../images/gatepage/card/HHT2/hht2_ep_11.png";
import HHT2ep12Card from "../images/gatepage/card/HHT2/hht2_ep_12.png";
import HHT2ep13Card from "../images/gatepage/card/HHT2/hht2_ep_13.png";
import HHT2ep14Card from "../images/gatepage/card/HHT2/hht2_ep_14.png";
import HHT2ep15Card from "../images/gatepage/card/HHT2/hht2_ep_15.png";
import HHT2ep16Card from "../images/gatepage/card/HHT2/hht2_ep_16.png";
import HHT2ep17Card from "../images/gatepage/card/HHT2/hht2_ep_17.png";
import HHT2ep18Card from "../images/gatepage/card/HHT2/hht2_ep_18.png";
import HHT2ep19Card from "../images/gatepage/card/HHT2/hht2_ep_19.png";
import HHT2ep20Card from "../images/gatepage/card/HHT2/hht2_ep_20.png";

import logo2 from "../images/gatepage/common/hht2_img_logo.png";
import hht2Charac01 from "../images/gatepage/common/hht2_img01.png";
import hht2Charac02 from "../images/gatepage/common/hht2_img02.png";
import title2 from "../images/gatepage/common/hht2_img_title.png";

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import styled from "styled-components";
import API from "../shared/api";

const New = () => {
    let location = useLocation();
    const params = new URLSearchParams(location.search)
    const studentStore = useSelector(store => store.userStore);
    const studentId = params.get("studentId");
    const currentEpisode = params.get("episodeCode");
    const studentNickName = params.get("studentNickName");
    const studentAvatarNo = params.get("studentAvatarNo");
    // const [productName, setProductName] = useState(params.get("productName"));
    const productName = params.get("productName");
    const baseUrl = "";
    const free = params.get("free")

    console.log(productName);
    console.log(studentStore.info.productName);
    console.log("학생계정 : " + studentId);
    console.log("free", free);

    // productId/courseId/bookId/episodeId/lessonId?studentId
    const HHT1 = {
        "EP1"  : {"link": free == 1 ? "/1/1/1/65/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo+"&free="+free :"/1/1/1/65/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo , "img":HHT1ep01Card, "code":HHT1ep01Code,  "title":"Happy to Help!" },
        "EP2"  : {"link": free == 1 ? "/1/1/1/146/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo+"&free="+free : "/1/1/1/146/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep02Card, "code":HHT1ep02Code,  "title":"What Have You Done?" },
        "EP3"  : {"link": free == 1 ? "#" : "/1/1/1/148/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep03Card, "code":HHT1ep03Code,  "title":"Hey! I’m Right Here!", "dim": free == 1 ? "ok" : "" },
        "EP4"  : {"link": free == 1 ? "#" :"/1/1/1/150/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep04Card, "code":HHT1ep04Code,  "title":"Why Are You a Pickle?" , "dim": free == 1 ? "ok" : ""},
        "EP5"  : {"link": free == 1 ? "#" :"/1/1/1/152/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep05Card, "code":HHT1ep05Code,  "title":"I Want Lemon Cheesecake!", "dim": free == 1 ? "ok" : "" },
        "EP6"  : {"link": free == 1 ? "#" :"/1/1/1/154/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep06Card, "code":HHT1ep06Code,  "title":"Where's the Ice Cream?" , "dim": free == 1 ? "ok" : ""},
        "EP7"  : {"link": free == 1 ? "#" :"/1/1/1/156/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep07Card, "code":HHT1ep07Code,  "title":"Cats Never Obey", "dim": free == 1 ? "ok" : "" },
        "EP8"  : {"link": free == 1 ? "#" :"/1/1/1/158/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep08Card, "code":HHT1ep08Code,  "title":"Don’t Run Away!" , "dim": free == 1 ? "ok" : ""},
        "EP9"  : {"link": free == 1 ? "#" :"/1/1/1/160/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep09Card, "code":HHT1ep09Code,  "title":"Not Everybody Can Sing", "dim": free == 1 ? "ok" : "" },
        "EP10" : {"link": free == 1 ? "#" :"/1/1/1/162/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep10Card, "code":HHT1ep10Code,  "title":"Why Don't You Sit Between Us?", "dim": free == 1 ? "ok" : "" },
        "EP11" : {"link": free == 1 ? "#" :"/1/1/1/164/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep11Card, "code":HHT1ep11Code,  "title":"The Hungry Lake" , "dim": free == 1 ? "ok" : ""},
        "EP12" : {"link": free == 1 ? "#" :"/1/1/1/166/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep12Card, "code":HHT1ep12Code,  "title":"Finders Keepers", "dim": free == 1 ? "ok" : "" },
        "EP13" : {"link": free == 1 ? "#" :"/1/1/1/168/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep13Card, "code":HHT1ep13Code,  "title":"Nobody Likes What I Like", "dim": free == 1 ? "ok" : "" },
        "EP14" : {"link": free == 1 ? "#" :"/1/1/1/170/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep14Card, "code":HHT1ep14Code,  "title":"You're Burning Up!", "dim": free == 1 ? "ok" : "" },
        "EP15" : {"link": free == 1 ? "#" :"/1/1/1/172/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep15Card, "code":HHT1ep15Code,  "title":"Who Doesn't Want to Go Home?" , "dim": free == 1 ? "ok" : ""},
        "EP16" : {"link": free == 1 ? "#" :"/1/1/1/174/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep16Card, "code":HHT1ep16Code,  "title":"Tony! You Should Go Home!", "dim": free == 1 ? "ok" : "" },
        "EP17" : {"link": free == 1 ? "#" :"/1/1/1/176/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep17Card, "code":HHT1ep17Code,  "title":"Holly's Spelling Bee Contest" , "dim": free == 1 ? "ok" : ""},
        "EP18" : {"link": free == 1 ? "#" :"/1/1/1/178/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep18Card, "code":HHT1ep18Code,  "title":"But You Hated Spelling!", "dim": free == 1 ? "ok" : "" },
        "EP19" : {"link": free == 1 ? "#" :"/1/1/1/180/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep19Card, "code":HHT1ep19Code,  "title":"Tony's Coming!" , "dim": free == 1 ? "ok" : ""},
        "EP20" : {"link": free == 1 ? "#" :"/1/1/1/182/1?&studentId="+studentId+"&oCode=1&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep20Card, "code":HHT1ep20Code,  "title":"Who Needs Comic Books for a Trip?", "dim": free == 1 ? "ok" : "" }
    }

    const HHT2 = {
        "EP1"  : {"link": free == 1 ? "/1/3/3/61/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo+"&free="+free : "/1/3/3/61/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo , "img":HHT2ep01Card, "code":HHT1ep01Code, "title":"Hello, Tony 1"  },
        "EP2"  : {"link": free == 1 ? "/1/3/3/143/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo+"&free="+free : "/1/3/3/143/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep02Card, "code":HHT1ep02Code, "title":"Hello, Tony 2" },
        "EP3"  : {"link": free == 1 ? "#" : "/1/3/3/224/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep03Card, "code":HHT1ep03Code, "title":"Harry's Books", "dim": free == 1 ? "ok" : "" },
        "EP4"  : {"link": free == 1 ? "#" : "/1/3/3/226/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep04Card, "code":HHT1ep04Code, "title":"Holly's Talent Show","dim": free == 1 ? "ok" : "" },
        "EP5"  : {"link": free == 1 ? "#" : "/1/3/3/228/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep05Card, "code":HHT1ep05Code, "title":"The Baseball Equipment" ,"dim": free == 1 ? "ok" : ""},
        "EP6"  : {"link": free == 1 ? "#" : "/1/3/3/230/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep06Card, "code":HHT1ep06Code, "title":"The Broken Toy Ship" ,"dim": free == 1 ? "ok" : ""},
        "EP7"  : {"link": free == 1 ? "#" : "/1/3/3/232/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep07Card, "code":HHT1ep07Code, "title":"The Monster Puppy 1" ,"dim": free == 1 ? "ok" : ""},
        "EP8"  : {"link": free == 1 ? "#" : "/1/3/3/234/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep08Card, "code":HHT1ep08Code, "title":"The Monster Puppy 2" ,"dim": free == 1 ? "ok" : ""},
        "EP9"  : {"link": free == 1 ? "#" : "/1/3/3/236/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep09Card, "code":HHT1ep09Code, "title":"No Onions, Please" ,"dim": free == 1 ? "ok" : ""},
        "EP10" : {"link": free == 1 ? "#" : "/1/3/3/238/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep10Card, "code":HHT1ep10Code, "title":"The Strange Cleaning Day" ,"dim": free == 1 ? "ok" : ""},
        "EP11" : {"link": free == 1 ? "#" : "/1/3/3/240/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep11Card, "code":HHT1ep11Code, "title":"Tony's Tummy Trouble" ,"dim": free == 1 ? "ok" : ""},
        "EP12" : {"link": free == 1 ? "#" : "/1/3/3/242/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep12Card, "code":HHT1ep12Code, "title":"Mirror, Mirror" ,"dim": free == 1 ? "ok" : ""},
        "EP13" : {"link": free == 1 ? "#" : "/1/3/3/244/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep13Card, "code":HHT1ep13Code, "title":"Pizza Delivery 1" ,"dim": free == 1 ? "ok" : ""},
        "EP14" : {"link": free == 1 ? "#": "/1/3/3/246/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep14Card, "code":HHT1ep14Code, "title":"Pizza Delivery 2" ,"dim": free == 1 ? "ok" : ""},
        "EP15" : {"link": free == 1 ? "#" : "/1/3/3/248/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep15Card, "code":HHT1ep15Code, "title":"The BBQ Contest 1" ,"dim": free == 1 ? "ok" : ""},
        "EP16" : {"link": free == 1 ? "#" : "/1/3/3/250/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep16Card, "code":HHT1ep16Code, "title":"The BBQ Contest 2" ,"dim": free == 1 ? "ok" : ""},
        "EP17" : {"link": free == 1 ? "#" : "/1/3/3/252/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep17Card, "code":HHT1ep17Code, "title":"Harry's Symptoms" ,"dim": free == 1 ? "ok" : ""},
        "EP18" : {"link": free == 1 ? "#" :"/1/3/3/254/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep18Card, "code":HHT1ep18Code, "title":"The Pillow Fight" ,"dim": free == 1 ? "ok" : ""},
        "EP19" : {"link": free == 1 ? "#" : "/1/3/3/256/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep19Card, "code":HHT1ep19Code, "title":"Tony's Phone Call" ,"dim": free == 1 ? "ok" : ""},
        "EP20" : {"link": free == 1 ? "#" : "/1/3/3/258/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT2ep20Card, "code":HHT1ep20Code, "title":"Goodbye, Tony" ,"dim": free == 1 ? "ok" : ""}
    }

    const Culture = {
        "EP1"  : {"link":"/3/5/1/300/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep01Card, "code":HHT1ep01Code, "title":""},
        "EP2"  : {"link":"/3/5/1/301/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep02Card, "code":HHT1ep02Code, "title":""},
        "EP3"  : {"link":"/3/5/1/302/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep03Card, "code":HHT1ep03Code, "title":""},
        "EP4"  : {"link":"/3/5/1/303/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep04Card, "code":HHT1ep04Code, "title":""},
        "EP5"  : {"link":"/3/5/1/304/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep05Card, "code":HHT1ep05Code, "title":""},
        "EP6"  : {"link":"/3/5/1/305/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep06Card, "code":HHT1ep06Code, "title":""},
        "EP7"  : {"link":"/3/5/1/306/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep07Card, "code":HHT1ep07Code, "title":""},
        "EP8"  : {"link":"/3/5/1/307/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep08Card, "code":HHT1ep08Code, "title":""},
        "EP9"  : {"link":"/3/5/1/308/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep09Card, "code":HHT1ep09Code, "title":""},
        "EP10" : {"link":"/3/5/1/309/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep10Card, "code":HHT1ep10Code, "title":""},
        "EP11" : {"link":"/3/5/1/310/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep11Card, "code":HHT1ep11Code, "title":""},
        "EP12" : {"link":"/3/5/1/311/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep12Card, "code":HHT1ep12Code, "title":""},
        "EP13" : {"link":"/3/5/1/312/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep13Card, "code":HHT1ep13Code, "title":""},
        "EP14" : {"link":"/3/5/1/313/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep14Card, "code":HHT1ep14Code, "title":""},
        "EP15" : {"link":"/3/5/1/314/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep15Card, "code":HHT1ep15Code, "title":""},
        "EP16" : {"link":"/3/5/1/315/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep16Card, "code":HHT1ep16Code, "title":""},
        "EP17" : {"link":"/3/5/1/316/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep17Card, "code":HHT1ep17Code, "title":""},
        "EP18" : {"link":"/3/5/1/317/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep18Card, "code":HHT1ep18Code, "title":""},
        "EP19" : {"link":"/3/5/1/318/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep19Card, "code":HHT1ep19Code, "title":""},
        "EP20" : {"link":"/3/5/1/319/1?&studentId="+studentId+"&oCode=2&studentNickName="+studentNickName+"&studentAvatarNo="+studentAvatarNo, "img":HHT1ep20Card, "code":HHT1ep20Code, "title":""}
    }

    const navigationCode = {
        "EP1": 18,
        "EP2": 19,
        "EP3": 0,
        "EP4": 1,
        "EP5": 2,
        "EP6": 3,
        "EP7": 4,
        "EP8": 5,
        "EP9": 6,
        "EP10": 7,
        "EP11": 8,
        "EP12": 9,
        "EP13": 10,
        "EP14": 11,
        "EP15": 12,
        "EP16": 13,
        "EP17": 14,
        "EP18": 15,
        "EP19": 16,
        "EP20": 17
    }


    // http://localhost:3000/1/1/1/65/1?&studentId=48&oCode=1
    // http://localhost:3000/1/1/1/65/1?&studentId=48&oCode=1

    // href="/1/1/1/65/1?&studentId=48&oCode=1"

    // href="/1/1/1/65/1?&studentId=48&oCode=1"
    let video_List;
    let imgPath = '';
    let filePath = '';

    let routerName = '';

    switch (productName.toUpperCase()) {
        case 'HHT1':
            routerName = 'hh1/learning-home';
            break;
        case 'HHT2':
            routerName = 'hh2/learning-home';
            break;
        case 'CULTURE':
            routerName = 'culture/learning-home';
            break;
        default:
            break;
    }

    let landingSelected = true;
    let initializerChecker = 0; // <Swiper>에 기술된 on Event들은 최초로딩시에 한번씩 실행되기때문에, 로딩이후 인터액션을 위한 카운터입니다.

    const swiperRef = useRef(null);
    const hoveringState = [];

    const selectedEpisodeHoverNormalizer = () => {
        for (let i = 0; i < hoveringState.length; i++) {

            if (hoveringState[i].index === currentEpisode) {
                hoveringState[i].state(false);
            }
        }
    };

    const [isVertical, setIsVertical] = useState(window.matchMedia('(orientation: portrait)').matches)

    useEffect(() => {
        const handleResize = () => {
            setIsVertical(window.matchMedia('(orientation: portrait)').matches)
        };

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const isDevice = /Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent);
        const redirectTarget = window.location.hostname === 'dcon.eggschool.net'?
            'https://e.eggschool.net/mypage'
            :
            window.location.hostname === 'devdcon.eggschool.net'?
                'https://e-dev.eggschool.net/mypage'
                :
                'https://e-dev.eggschool.net/mypage';


        let isRedirect = false;
        if(isDevice && isVertical && window.innerWidth < 600){
            isRedirect = true;
            // window.location.replace(redirectTarget);
        }else if(isDevice && !isVertical && window.innerHeight < 500){
            isRedirect = true;
            // window.location.replace(redirectTarget);
        }

        if(!isRedirect && isDevice && isVertical){
            alert("원활한 학습 환경을 위해 기기를 가로모드로 변경해주세요");

                // if (isVertical) {
                //
                //     const element = document.documentElement;
                //     console.log(element)
                //     // Request full-screen mode
                //     if (element.requestFullscreen) {
                //         element.requestFullscreen();
                //     } else if (element.mozRequestFullScreen) {
                //         element.mozRequestFullScreen();
                //     } else if (element.webkitRequestFullscreen) {
                //         element.webkitRequestFullscreen();
                //     } else if (element.msRequestFullscreen) {
                //         element.msRequestFullscreen();
                //     }
                //
                //     if (window.screen.orientation) {
                //         window.screen.orientation.lock('landscape');
                //     } else if (window.screen.lockOrientation) {
                //         window.screen.lockOrientation('landscape');
                //     } else if (window.screen.mozLockOrientation) {
                //         window.screen.mozLockOrientation('landscape');
                //     } else if (window.screen.msLockOrientation) {
                //         window.screen.msLockOrientation('landscape');
                //     } else if (window.screen.orientation && window.screen.orientation.type) {
                //         window.screen.orientation.type = 'landscape-primary';
                //     }
                // }
        }

    }, [isVertical]);


    const onClickStudyPopup = (linkURL) => {
        let url = linkURL;
        let newWindow;

        const isDevice = /Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent);

        newWindow = window.open(url, 'Egg School', 'width=' + window.screen.availWidth + ',height=' + window.screen.availHeight);

        let windowLoadInterval = setInterval(function() {
            if (newWindow.document.readyState === 'complete') {
                clearInterval(windowLoadInterval);

                let alertShown = false;
                function handleResize(newWindow) {
                    if (newWindow.innerWidth < 1270) {
                        if (!alertShown) {
                            newWindow.alert("원활한 학습 환경을 위해 학습 화면을 조정합니다.");
                            alertShown = true;
                        }
                        newWindow.resizeTo(1270, 720);
                    } else {
                        // Reset the alertShown flag when the window width is above 1270px
                        alertShown = false;
                    }
                }

                // 갤탭에서 영상 전체화면으로 안바뀌는 문제 해결 위해 pc에만 사이즈 조정 함수 적용
                if(!isDevice) {
                    newWindow.addEventListener("resize", () => handleResize(newWindow));
                }else{
                    return;
                }

            }
        }, 100);

        return null

    }

    const EpisodeCard = ({index, baseUrl, value}) => {
        const [isHovering, setIsHovering] = useState(index === currentEpisode && landingSelected ? true : false);
        hoveringState.push({index: index, state: setIsHovering});
        const handleMouseEnter = () => {
            if (landingSelected) {
                landingSelected = false;
                selectedEpisodeHoverNormalizer();
            }
            setIsHovering(true);
        };

        const handleMouseLeave = () => {
            setIsHovering(false);
        };

        // const styles={
        //     bold:{
        //         textAlign: 'center',
        //         color: '#515151',
        //         display: "-webkit-box",
        //         "-webkit-box-orient": "vertical",
        //         overflow: "hidden",
        //         "-webkit-line-clamp": "1"
        //     },
        //     '@media (max-width: 1400px)': {
        //         bold: {
        //         backgroudColor:'red',
        //
        //         }
        //     }
        // }

        const TextBlock = styled.div`
          .genre_img {
            width: 40%;
            maxWidth: 100%;
            margin: 1vh auto;
            display: block;
          }

          .bold {
            text-align: center;
            color: #515151;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          @media (max-width: 1200px) {
            .genre_img {
              width: 60%;
            }

            .bold {
              display: none;
            }

          }
        `;

        const Box = styled.div`
            background-color: #ffff;
            box-shadow: rgba(0, 0, 0, 0.12) 6px 6px 7px;
            border-radius: 15px;

            @media (max-width: 1400px) {
                border-radius: 8px;
            }
        `;

        return (

            <div
                className="episodeElem"
                style={{
                    width: "13vw",
                    height: "17.5vw",
                    top: isHovering ? "0vh" : "2.5vh",
                    boxSizing: "border-box",
                    display: "inline-block",
                    position: "relative",
                    transition: "top 0.2s ease-in-out",
                    cursor:"pointer",
                    opacity: value.dim == "ok" ? "0.4" : 1,
                    // backgroundColor:"red",

                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={()=> onClickStudyPopup(baseUrl + value.link)}
            >

                <Box className="box">
                    <div className="text">

                        <div className="genre">
                            <img style={{width: "100%", maxWidth: "100%"}} src={value.img}/>
                            <TextBlock>
                                <img className="genre_img"
                                     src={value.code}/>
                                <p className="bold">{value.title}</p>
                            </TextBlock>
                        </div>

                    </div>
                </Box>

            </div>
        );
    }

    const ArrowBlock = styled.div`

        .prev {
          position: fixed;
          left: 10vw;
          top: 53vh;
          text-align: center;
        }

        .next{
          position: fixed;
          right: 10vw;
          top: 53vh;
          text-align: center;
        }

      @media (max-width: 1388px){
        .prev {
          left: 8vw;
          top: 50vh;
        }

        .next{
          right: 8vw;
          top: 50vh;
        }
      }

      @media (max-width: 1024px), (max-height:768px) {
        .prev {
          left: 5vw;
          top: 48vh;
        }

        .next{
          right: 5vw;
          top: 48vh;
        }
      }
    `;

    const EpisodeList = () => {
        let elem = [];
        let index = 0;
        let productOBJ = {};

        switch (productName) {
            case "HHT1":
                productOBJ = HHT1;
                break;
            case "HHT2":
                productOBJ = HHT2;
                break;
            case "CULTURE":
                productOBJ = Culture;
                break;
        }
        Object.entries(productOBJ).forEach(([key, value]) => {

            elem.push(<SwiperSlide>
                <EpisodeCard index={key} baseUrl={baseUrl} value={value}/>
            </SwiperSlide>)

        });

        return (
            <Swiper
                modules={[Navigation]}
                slidesPerView={5}
                ref={swiperRef}
                loop={true}
                onSwiper={(swiper) => console.log(swiper)}
                spaceBetween={15}
                onSlideChange={() => initializerChecker > 0 ? landingSelected = false : initializerChecker++}

                onSlideChangeTransitionEnd={() => !landingSelected ? selectedEpisodeHoverNormalizer() : ""}

                autoHeight={true}
                initialSlide={currentEpisode !== null ? navigationCode[currentEpisode] : 0}
                style={{minHeight: "26vw", maxWidth: "69.1vw", textAlign:"center"}}
            >
                {elem}

            </Swiper>
        );
    };

    return (
        <>
            {
                productName == 'HHT1' &&

                <div style={{backgroundColor: "#F6F4DF", minHeight: "100vh"}}>
                    <div style={{display: "flex"}}>
                        {/*    <div style={{width: "50%", textAlign: "left", minHeight: "5vh"}}>*/}
                        {/*<span onClick={() => (setProductName('HHT1'))}>*/}
                        {/*  HHT1*/}
                        {/*</span>*/}
                        {/*        <span onClick={() => (setProductName('HHT2'))}>*/}
                        {/*  HHT2*/}
                        {/*</span>*/}
                        {/*        <span onClick={() => (setProductName('CULTURE'))}>*/}
                        {/*  Culture*/}
                        {/*</span>*/}
                        {/*    </div>*/}
                        <div style={{position: "fixed", top: "5vh", left: "5vh"}}>
                            <img style={{width: "90%"}} src={logo1} alt=""/>
                        </div>
                        <div style={{
                            width: "50%",
                            textAlign: "right",
                            minHeight: "5vh",
                            position: "fixed",
                            top: "5vh",
                            right: "5vh"
                        }}>
                            {/*
                    <Link to={"/" + routerName}>
                        <img style={{maxWidth: "3vh"}} className="close-btn" src={HomeBtnClose} alt=""/>
                    </Link>
                    */}
                        </div>

                    </div>
                    <div style={{position: "fixed", textAlign: "right", top: "22vh", right: "0"}}>
                        <img style={{width: "90%"}} src={charac02} alt=""/>
                    </div>
                    <div style={{justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <div style={{
                            textAlign: "center",
                            // display: "inline-flex",
                            padding: "2.5vh",
                            fontSize: "5vh",
                            marginTop: "10vh"
                        }}>
                            <img style={{width: "90%", maxWidth: "100%",}} src={title1} alt=""/>
                            <p style={{fontSize: "2.2vh", marginTop: "1vh", color: '#515151'}}>애니메이션으로 만나는 미국초등학생의
                                일상</p>
                        </div>
                    </div>
                    <div style={{marginTop: "4vh", minHeight: "44vh"}}>
                        <EpisodeList/>
                        <div style={{clear: "both"}}></div>
                    </div>
                    <ArrowBlock>
                        <div className="prev" onClick={() => swiperRef.current.swiper.slidePrev()}>
                            <img style={{width: "50%", cursor: "pointer"}} src={leftButton}/>
                        </div>
                        <div className="next" onClick={() => swiperRef.current.swiper.slideNext()}>
                            <img style={{width: "50%", cursor: "pointer"}} src={rightButton}/>
                        </div>
                    </ArrowBlock>
                    <div style={{position: "fixed", bottom: "0"}}>
                        <img style={{width: "90%"}} src={charac01} alt=""/>

                    </div>

                </div>
            }
            {
                productName == 'HHT2' &&

                <div style={{backgroundColor: "#F6F4DF", minHeight: "100vh"}}>

                    <div style={{display: "flex"}}>
                        {/*    <div style={{width: "50%", textAlign: "left", minHeight: "5vh"}}>*/}
                        {/*<span onClick={() => (setProductName('HHT1'))}>*/}
                        {/*  HHT1*/}
                        {/*</span>*/}
                        {/*        <span onClick={() => (setProductName('HHT2'))}>*/}
                        {/*  HHT2*/}
                        {/*</span>*/}
                        {/*        <span onClick={() => (setProductName('CULTURE'))}>*/}
                        {/*  Culture*/}
                        {/*</span>*/}
                        {/*    </div>*/}
                        <div style={{position: "fixed", top: "5vh", left: "5vh"}}>
                            <img style={{width: "90%"}} src={logo2} alt=""/>
                        </div>
                        <div style={{
                            width: "50%",
                            textAlign: "right",
                            minHeight: "5vh",
                            position: "fixed",
                            top: "5vh",
                            right: "5vh"
                        }}>
                            {/*
                    <Link to={"/" + routerName}>
                        <img style={{maxWidth: "3vh"}} className="close-btn" src={HomeBtnClose} alt=""/>
                    </Link>
                    */}
                        </div>

                    </div>

                    <div style={{position: "fixed", textAlign: "right", bottom: "0", right: "5vh",zIndex:"100"}}>

                        <img style={{width: "90%"}} src={hht2Charac02} alt=""/>
                    </div>

                    <div style={{justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <div style={{
                            textAlign: "center",
                            // display: "inline-flex",
                            padding: "2.5vh",
                            fontSize: "5vh",
                            marginTop: "10vh"
                        }}>
                            <img style={{width: "90%", maxWidth: "100%",}} src={title2} alt=""/>
                            <p style={{fontSize: "2.2vh", marginTop: "1vh", color: '#515151'}}>시트콤으로 만나는 미국초등학생의
                                방학 생활</p>
                        </div>
                    </div>

                    <div style={{marginTop: "4vh", minHeight: "44vh"}}>
                        <EpisodeList/>
                        <div style={{clear: "both"}}></div>

                    </div>
                    <div style={{position:"fixed", backgroundColor:"#F6F4DF", height:"35vh", width:"100%", bottom:"0"}}>
                        <ArrowBlock>
                            <div className="prev" onClick={() => swiperRef.current.swiper.slidePrev()}>
                                <img style={{width: "50%", cursor: "pointer"}} src={leftButton}/>
                            </div>
                            <div className="next" onClick={() => swiperRef.current.swiper.slideNext()}>
                                <img style={{width: "50%", cursor: "pointer"}} src={rightButton}/>
                            </div>
                        </ArrowBlock>
                        <div style={{position: "fixed", bottom: "0", zIndex:"100"}}>
                            <img style={{width: "90%"}} src={hht2Charac01} alt=""/>

                        </div>
                    </div>

                </div>
            }
        </>
    );
};


export default New;