import React, { useEffect, useRef, useState,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
//hhj
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CloseActivityModal from "../../components/modal/CloseActivityModal";
import CancelIcon from "../../images/activity-sitcom/cancel-icon.png";
import HelpIcon from "../../images/activity-sitcom/help-icon.png";
import GuideAni from "../../images/direction/quide-fingure.png";
import CenterLigth from "../../images/word-play/center-light.png";
import UserFaceDefault from "../../images/word-play/user-face.png";
import UserBody from "../../images/word-play/user-body.png";
import UserImg from "../../images/word-play/user-img.png";

import countNum1 from "../../images/word-play/count-1.png";
import countNum2 from "../../images/word-play/count-2.png";
import countNum3 from "../../images/word-play/count-3.png";
import resultCorrect from "../../images/word-play/excellent.png";
import resultOops from "../../images/word-play/oops.png";

import WinnerImg from "../../images/word-play/winner-bg.png";

import PopReply from "../../images/pop-default/restart-btn.png";
import PopHome from "../../images/pop-default/home-btn.png";
import PopNext from "../../images/pop-default/next-btn.png";

// 클릭시 음향
import clickSound from "../../audios/click_sound.mp3";
// 임시 카운트 음향
import countBeep from "../../audios/timer.mp3";
// 정답 음향1
import goodAnswerSound1 from "../../audios/tony_Excellent.mp3";
// 정답 음향2
import goodAnswerSound2 from "../../audios/holly_Nicework.mp3";
// 정답 음향3
import goodAnswerSound3 from "../../audios/harry_Goodjob.mp3";
// 오답 음향
import wrongAnswerSound from "../../audios/DC_Oops_B1.mp3";


import thropy from "../../images/word-play/thropy.png";

//캐릭터
import UserHolly from "../../images/word-play/user-holly.png";
import UserHarry from "../../images/word-play/user-harry.png";
import UserTony from "../../images/word-play/user-tony.png";
import UserJenny from "../../images/word-play/user-jenny.png";
import UserAndy from "../../images/word-play/user-andy.png";
import UserThomas from "../../images/word-play/user-thomas.png";
import UserCharles from "../../images/word-play/user-charles.png";
import UserLisa from "../../images/word-play/user-lisa.png";
import UserBen from "../../images/word-play/user-ben.png";
import UserMike from "../../images/word-play/user-mike.png";
import UserEmma from "../../images/word-play/user-emma.png";

import WinHolly from "../../images/word-play/holly_win.png";
import WinHarry from "../../images/word-play/holly_win.png";
import WinTony from "../../images/word-play/holly_win.png";
import WinJenny from "../../images/word-play/holly_win.png";
import WinAndy from "../../images/word-play/holly_win.png";
import WinThomas from "../../images/word-play/holly_win.png";
import WinCharles from "../../images/word-play/holly_win.png";
import WinLisa from "../../images/word-play/holly_win.png";
import WinBen from "../../images/word-play/holly_win.png";
import WinMike from "../../images/word-play/holly_win.png";
import WinEmma from "../../images/word-play/holly_win.png";

import LoseHolly from "../../images/word-play/tony_lose.png";
import LoseHarry from "../../images/word-play/tony_lose.png";
import LoseTony from "../../images/word-play/tony_lose.png";
import LoseJenny from "../../images/word-play/tony_lose.png";
import LoseAndy from "../../images/word-play/tony_lose.png";
import LoseThomas from "../../images/word-play/tony_lose.png";
import LoseCharles from "../../images/word-play/tony_lose.png";
import LoseLisa from "../../images/word-play/tony_lose.png";
import LoseBen from "../../images/word-play/tony_lose.png";
import LoseMike from "../../images/word-play/tony_lose.png";
import LoseEmma from "../../images/word-play/tony_lose.png";


import draw from "../../images/word-play/draw.png";

import Particle from "../../images/word-play/particle.png";
import Booth from "../../images/word-play/score-board.png";
import BoothOn from "../../images/word-play/score-board-on.png";


// 팝업 이미지
import WordPlayPop01 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep01_book_wp_02.jpg";
import WordPlayPop02 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep02_book_wp_02.jpg";
import WordPlayPop03 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep03_book_wp_02.jpg";
import WordPlayPop04 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep04_book_wp_02.jpg";
import WordPlayPop05 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep05_book_wp_02.jpg";
import WordPlayPop06 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep06_book_wp_02.jpg";
import WordPlayPop07 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep07_book_wp_02.jpg";
import WordPlayPop08 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep08_book_wp_02.jpg";
import WordPlayPop09 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep09_book_wp_02.jpg";
import WordPlayPop10 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep10_book_wp_02.jpg";
import WordPlayPop11 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep11_book_wp_02.jpg";
import WordPlayPop12 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep12_book_wp_02.jpg";
import WordPlayPop13 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep13_book_wp_02.jpg";
import WordPlayPop14 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep14_book_wp_02.jpg";
import WordPlayPop15 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep15_book_wp_02.jpg";
import WordPlayPop16 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep16_book_wp_02.jpg";
import WordPlayPop17 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep17_book_wp_02.jpg";
import WordPlayPop18 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep18_book_wp_02.jpg";
import WordPlayPop19 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep19_book_wp_02.jpg";
import WordPlayPop20 from "../../images/word-play/books/HHT1_WordPlay/book_img/hht1_ep20_book_wp_02.jpg";
import PopStart from "../../images/word-play/books/HHT1_WordPlay/pop_btn_start.png"



/*css*/
import "../../css/wordplay.css";
import API from "../../shared/api";
import {object} from "bfj/src/events";

import * as userStore from "../../modules/userStore";


// 사운드 이미지
import Sound from "../../images/word-play/word-sound.png";

// 처음 퀴즈 순서 섞었는지
let isFirst = true;

// 셔플후 오브젝트 담음, 렌더링 업데이트시 초기화 방지;
let tempSortedJson = {};

// 뒤로가기 클릭후 다른에피소드 진입시 원본 오브젝트 비교로 에피소드 변경 감지
let compareHistoryObject = {};
let tempComputerAvaterNo = Math.floor(Math.random() * 11);

const Index = () => {
  let location = useLocation();
  let history = useHistory();
  const userSelector = useSelector(store => store.userStore);
  const dispatch = useDispatch();
  const episodeInfo = userSelector.info.episodes.find(item => item.episodeId === location.state.episodeId);
  const lessonInfo = episodeInfo.lessons.find(item => item.lessonId === location.state.lessonId);
  const actInfo = lessonInfo.acts.find(item => item.actId === location.state.actId);
  const mediaPath = actInfo.media_path;
  const [orgActList, setOrgActList] = useState([]);
  const [isPopUp, setIsPopUp] = useState(true);
  const studentAvatarNo = parseInt(userSelector.info.studentAvatarNo) - 1;
  const onClickIsPopUpSet = () => {

    if(isPopUp){
      setIsPopUp(false);
    }else{

      // 문제 클릭 유도 팝업, 문제 풀이중 3초 카운트다운 인터벌 제거
      const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }

      // 문제 선택했을시 고려 변수 초기화해줌
      audioRef.current.pause();
      setMyStatus(0);
      setComputerStatus(0);
      setMyAnswer("");
      setComputerAnswer("");
      setMyTurn(false);
      setComputerTurn(false);
      setIsAnswerSelected(false);
      setTimer(6);
      myTurnCounter = 6;
      setIsPopUp(true);

    }


  }

  if(tempComputerAvaterNo === studentAvatarNo ){
    while(tempComputerAvaterNo === studentAvatarNo){
      tempComputerAvaterNo = Math.floor(Math.random() * 11);
    }
  }



  // 팝업 이미지
  const popImg=[
    WordPlayPop01,
    WordPlayPop02,
    WordPlayPop03,
    WordPlayPop04,
    WordPlayPop05,
    WordPlayPop06,
    WordPlayPop07,
    WordPlayPop08,
    WordPlayPop09,
    WordPlayPop10,
    WordPlayPop11,
    WordPlayPop12,
    WordPlayPop13,
    WordPlayPop14,
    WordPlayPop15,
    WordPlayPop16,
    WordPlayPop17,
    WordPlayPop18,
    WordPlayPop19,
    WordPlayPop20,

  ]


  /*Jenny Andy Lisa Ben 이미지 필요*/
  const avatarInfo = [
    {name:"Holly",img:UserHolly,win:WinHolly,lose:LoseHolly},
    {name:"Harry",img:UserHarry,win:WinHarry,lose:LoseHarry},
    {name:"Tony",img:UserTony,win:WinTony,lose:LoseTony},
    {name:"Jenny",img:UserJenny,win:WinJenny,lose:LoseJenny},
    {name:"Andy",img:UserAndy,win:WinAndy,lose:LoseAndy},
    {name:"Thomas",img:UserThomas,win:WinThomas,lose:LoseThomas},
    {name:"Charles",img:UserCharles,win:WinCharles,lose:LoseCharles},
    {name:"Lisa",img:UserLisa,win:WinLisa,lose:LoseLisa},
    {name:"Ben",img:UserBen,win:WinBen,lose:LoseBen},
    {name:"Mike",img:UserMike,win:WinMike,lose:LoseMike},
    {name:"Emma",img:UserEmma,win:WinEmma,lose:LoseEmma}
  ]


  const closeActivityModalRef = useRef();
  const handleClose = () => {
    closeActivityModalRef.current.openModal();
  };

  let originalJson = require("./" + actInfo.actName + ".json");

  // 뒤로가기 눌렀다가 재 진입시 초기화
  window.addEventListener('popstate', (event) => {
    retryAllQuizs();
    isFirst = true;
  });



  if(compareHistoryObject !== originalJson){
    isFirst = true;
  }


  // 0 이면 문제 선택화면 렌더링, 이외 숫자면 제이슨 내부 data index 문제 가져옴
  const [nowQuizNum, setNowQuizNum] = useState(0);

  // 내점수 100점 단위로 올라감
  const [myScore, setMyScore] = useState(0);

  // 컴퓨터 점수 100점 단위로 올라감
  const [computerScore, setComputerScore] = useState(0);

  // 정답제출 전 0, 정답제출 1, 오답 제출2
  const [myStatus, setMyStatus] = useState(0);

  // 학습자 정답제출 전 0, 학습자 오답제출 제출 1, 학습자 정답제출 제출2
  const [computerStatus, setComputerStatus] = useState(0);

  // 정답 선택 문자열
  const [myAnswer, setMyAnswer] = useState("");

  // 컴퓨터 정답 문자열
  const [computerAnswer, setComputerAnswer] = useState("");

  // 정답 선택후 계속 클릭하는것 방지
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);

  // 문제 풀이 시작후 내차례일때
  const [myTurn, setMyTurn] = useState(false);

  // 문제 풀이 시작후 컴퓨터 차례일때
  const [computerTurn, setComputerTurn] = useState(false);

  // 타이머
  const [timer, setTimer] = useState(6);

  // 0은 동점, 1은 학습자가 이기는중, 2는 컴퓨터가 이기는중
  const [whoIsWinner, setWhoIsWinner] = useState(0);

  // 카운트다운 숫자 이미지
  const countNumbers = ["",countNum1,countNum2,countNum3];

  // 선택된 퀴즈 임시 전역 변수
  let tempQuizObj = {};

  // 선택된 퀴즈 정답 임시 전역 변수
  let tempSavedAnswer = "";

  // 인터벌용 카운터
  let myTurnCounter = 6;

  // 오디오 컨트롤
  const audioRef = useRef(new Audio());

  // 전체 퀴즈 갯수
  const totalQuizQuantity = originalJson.data.length;



  const [isAllQuizSolved , setIsAllQuizSolved] = useState(false)
  // 퀴즈 순서 섞는 메소드
  const firstTimeQuizShuffle = (quizToShuffle) => {
    isFirst = false;
    compareHistoryObject = quizToShuffle;
    let shuffledNumberList = [];
    let domNumberList = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16"]

    for(let i = shuffledNumberList.length; i< totalQuizQuantity; i++){
      let newNumber = Math.floor(Math.random() * totalQuizQuantity) + 1;
      shuffledNumberList.push(newNumber);
      shuffledNumberList = [...new Set(shuffledNumberList)];
      i = shuffledNumberList.length - 1;
    }

    let quizBeforeShuffle = quizToShuffle.data;
    quizToShuffle.data = [];
    for(let i = 0; i< totalQuizQuantity; i++){
      quizBeforeShuffle[i].number = shuffledNumberList[i];

      quizBeforeShuffle[i].domNumber = domNumberList[shuffledNumberList[i] - 1];

    }

    function sortByNewlyGivenNumbers (data){
      let sortedData;
      sortedData = data.sort(function(a,b){
        return a.number - b.number;
      })

      return sortedData;
    }

    quizToShuffle.data = sortByNewlyGivenNumbers(quizBeforeShuffle);

    return quizToShuffle;

  }

  // 받아온 원본 제이슨에서 값을 섞어줌
  tempSortedJson = isFirst === true ? firstTimeQuizShuffle(originalJson) : tempSortedJson;

  // 퀴즈를 선택시, 해당 퀴즈의 넘버를 기반으로 어떤 퀴즈를 선택하였는지 렌더링을 바꿈, 오브젝트 number 속성에 0은 없기때문에 0번은 퀴즈 선택씬으로 사용
  const chooseQuiz = (quizNum) => {
    audioRef.current.src = clickSound;
    audioRef.current.play();
    setNowQuizNum(quizNum);
  };

  /*
  * 최초 렌더링시 nowQuizNum이 0 이기 때문에 퀴즈 선택씬을 만들고, 각 DOM에는 chooseQuiz에 어떤 퀴즈 번호를 넣을지 렌더링해줌
  * 온클릭해서 문제 풀고서 이후에 다시 정답이 제출된후 nowQuizNum이 0이 되면 선택씬으로 복귀
  */
  const makeQuizNumberDom = (jsonInput) => {

    const quizs = [];
    let unsolvedGuide = true;
    for(let i = 0; i < jsonInput.data.length; i++) {

      // 동적 생성된 정답 오답, 아직 풀지 않은 문제 체크
      if(jsonInput.data[i].solvedQuiz === undefined){

        // 처음이나 혹은 매번 문제를 풀때마다 아직 학습자가 풀지 않은 첫번째 문제에만 문제 해결 가이드를 넣어줌
        if(unsolvedGuide === true){
          quizs.push(
              <div className="word-box on" onClick={() => chooseQuiz(jsonInput.data[i].number)}>
                <div className="wrap">
                  <span className="num">{jsonInput.data[i].domNumber}</span>
                  <span className="answer"></span>
                </div>
                <div className="click-guide" ref={clickGuide} style={{ "display" : "none"}}>
                  <img src={GuideAni} alt="" />
                </div>
              </div>
          )
          unsolvedGuide = false;

        }else{
          quizs.push(
              <div className="word-box on" onClick={() => chooseQuiz(jsonInput.data[i].number)}>
                <div className="wrap">
                  <span className="num">{jsonInput.data[i].domNumber}</span>
                  <span className="answer"></span>
                </div>
              </div>
          )
        }
      }else if(jsonInput.data[i].solvedQuiz === true){
        quizs.push(
            <div className="word-box">
              <div className="wrap">
                {/*<span className="num" dangerouslySetInnerHTML={ { __html: jsonInput.data[i].answer } }>{}</span>*/}
                <span className={jsonInput.data[i].answer.length >= 15 ? "num word_text_over" : "num"} dangerouslySetInnerHTML={ { __html: jsonInput.data[i].answer } }>{}</span>
                <span className="answer"></span>
              </div>
            </div>
        )
      }else if(jsonInput.data[i].solvedQuiz === false){
        quizs.push(
            <div className="word-box on">
              <div className="wrap">
                {/*<span className="num wrong_num_override" dangerouslySetInnerHTML={ { __html: jsonInput.data[i].answer } }>{}</span>*/}
                <span className={jsonInput.data[i].answer.length >= 15 ? "num wrong_num_override word_text_over" : "num wrong_num_override"} dangerouslySetInnerHTML={ { __html: jsonInput.data[i].answer } }>{}</span>
                <span className="answer"></span>
              </div>
            </div>
        )
      }
    }


    // 기본적으로 매 에피소드 16문제가 있다고 가정하고 이에 미달하는 퀴즈 갯수를 가질시 퀴즈 없는 DOM 렌더링
    const noQuiz = 16 - totalQuizQuantity;

    if(noQuiz > 0){
      for(let j = 0; j < noQuiz; j++)
      {
        quizs.push(
            <div className="word-box none">
            </div>
        )
      }
    }

    guideTimer();
    return quizs;
  }

  const chosenQuiz = (quizInput) => {

    console.log(quizInput)

    // 문제 클릭시 선택 답안 위치를 항상 좌우 랜덤으로 바꿔줌
    // 시간 지난후 답 선택 안했을시 컴퓨터 정답
    tempQuizObj = quizInput;

    // 전역변수 할당
    tempSavedAnswer = quizInput.optionA;

    // 정답인 보기, 오답인 보기
    let trueAnswer = quizInput.optionA;
    let falseAnswer = quizInput.optionB;


    let stringCountAnswerA = trueAnswer.length;
    let stringCountAnswerB = falseAnswer.length;

    let makeLargerAnswerSpace = false;
    if(stringCountAnswerA > 8 || stringCountAnswerB > 8){
      makeLargerAnswerSpace = true
    }

    let makeRand1or0;
    if(myTurn === false && computerStatus !== 1){
      setMyTurn(true);
    }

    // 문제 좌우 위치 임의 생성후, 정답 제출시 좌우 위치 재생성 방지
    if(isAnswerSelected === false && quizInput.tempAnswerLocation === undefined){
      makeRand1or0 = Math.floor(Math.random() * 2);
      quizInput.tempAnswerLocation = makeRand1or0;
    }

    let audio = new Audio(mediaPath + '/' +  quizInput.audio + "?" + Date.now());


    const onAudio = () => {
      console.log("audio1")
      audio.play();
    };


    let change_left_right;
    if((makeRand1or0 === 1 && quizInput.tempAnswerLocation === undefined) || quizInput.tempAnswerLocation === 1){
      change_left_right =
          <div className="quiz-word">

            {/* className 정답을 선택 안했을때 했을때 각각에 보기의 글자수가 긴지 아닌지에 따라 4가지 분기*/}
            {/* onClick 정답을 선택 했을때, 시간이 다 지나갔을때에는 onclick 비워서 중복클릭 못하게 막음*/}
              <span className={isAnswerSelected === false ? makeLargerAnswerSpace? "word-pick bigger-word-pick" : "word-pick" : makeLargerAnswerSpace? "word-pick answered bigger-word-pick" : "word-pick answered"}
                    onClick={() => isAnswerSelected === false && computerTurn === false ? submitAnswer(quizInput, trueAnswer) : ""}
                    dangerouslySetInnerHTML={ { __html: trueAnswer } }
              >
              </span>
            <span className={makeLargerAnswerSpace ? "word-pick bigger-word-pick":"word-pick"}
                  onClick={() => isAnswerSelected === false && computerTurn === false ? submitAnswer(quizInput, falseAnswer) : ""}
                  dangerouslySetInnerHTML={ { __html: falseAnswer } }
            >
              </span>
          </div>
    }else if((makeRand1or0 === 0 && quizInput.tempAnswerLocation === undefined) || quizInput.tempAnswerLocation === 0){
      change_left_right =
          <div className="quiz-word">
              <span className={makeLargerAnswerSpace ? "word-pick bigger-word-pick":"word-pick"}
                    onClick={() => isAnswerSelected === false && computerTurn === false ? submitAnswer(quizInput, falseAnswer) : ""}
                    dangerouslySetInnerHTML={ { __html: falseAnswer } }
              >
              </span>
            <span className={isAnswerSelected === false ? makeLargerAnswerSpace? "word-pick bigger-word-pick" : "word-pick" : makeLargerAnswerSpace? "word-pick answered bigger-word-pick" : "word-pick answered"}
                    onClick={() => isAnswerSelected === false && computerTurn === false ? submitAnswer(quizInput, trueAnswer) : ""}
                  dangerouslySetInnerHTML={ { __html: trueAnswer } }
              >
              </span>
          </div>
    }
    return (

        <div className="word-select-quiz inner" >

          <p className="question">Choose the answer.</p>
          <div className="wordplay-quiz">
            <div className="quiz-layer">

              <img className="quiz-pic" src={ mediaPath + "/" + quizInput.quizImg + "?" + Date.now()} alt=""/>
              <img
                  className="sound-icon"
                  src={Sound}
                  onClick={onAudio}
                  alt=""
              />
            </div>
            {change_left_right}
          </div>
        </div>
    )
  }

  /* 셋 인터벌 내부에서 외부 변화 감지 불가 이슈로, 순수 카운터로만 사용하고 실제 시간 변화는 이후 timer useEffect 에서 기술 */
   useEffect(() => {
    if(myTurn === true && nowQuizNum !== 0){

      // 이전 퀴즈 인터벌 강제 삭제후 새로운 인터벌
      const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }

      let noChoiceInterval = setInterval(function(){
        myTurnCounter--;
        setTimer(myTurnCounter);
        if(myTurnCounter === 0 ){
          clearInterval(noChoiceInterval);
        }
      }, 1000);
    }
  }, [myTurn, nowQuizNum]);


   /*카운트 변하는동안 답안선택 체크 및, 타이머 효과음*/
   useEffect( () => {

     // 타이머보다 학습자가 우선 반응 할수 있기때문에, myStatus로 답안 제출 전인지 우선 체크 (오디오 ref 퍼포먼스)
     if(myStatus !== 0 || nowQuizNum === 0){
       return
     }
       audioRef.current.src = countBeep;
       // 카운트 소리 임시
       if(timer !== 0 && timer < 4){
           audioRef.current.play();
       }

       if (timer === 0 && myAnswer !== tempSavedAnswer) {
         if(computerTurn === false){

           setComputerTurn(true);
         }
       }
   },[timer])

  /* 컴퓨터 시간초과 득점후 초기화*/
   useEffect(() => {
    if(myAnswer === "" && myAnswer !== tempSavedAnswer && myStatus !== 2 && myStatus === 0 && computerTurn){

        audioRef.current.src = wrongAnswerSound;
        audioRef.current.play();

        setComputerScore(computerScore + 100);
        setComputerStatus(1);
        setMyStatus(2);
        setComputerAnswer(tempSavedAnswer);
        setComputerTurn(true);
        setTimer(6);
        tempQuizObj.solvedQuiz = false;
        setIsAnswerSelected(true);

      updateResultToOriginal();

        setTimeout(()=>{
          setMyStatus(0);
          setComputerStatus(0);
          setComputerAnswer("");
          setComputerTurn(false);
          setIsAnswerSelected(false);
          setNowQuizNum(0);
        }, 4100);

    }else if(myAnswer === tempSavedAnswer && computerTurn === false){
      return
    }

  }, [computerTurn]);

  const submitAnswer = (quiz, answer) => {
    myTurnCounter = 0;
    setIsAnswerSelected(true);

    // 이전 퀴즈 인터벌 강제 삭제후 새로운 인터벌
    const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }

    setTimer(0);
    myTurnCounter = 0;

    let timeOut = 0;
    /*정답일때*/
    if(quiz.answer === answer)
    {
      quiz.solvedQuiz = true;
      setMyStatus(1);
      setComputerStatus(2);
      setMyAnswer(answer);
      setMyScore(myScore + 100);
      audioRef.current.src = goodAnswerSound1;

      timeOut = 2100;
    }else{

      /*오답일때*/
      setComputerTurn(true);
      quiz.solvedQuiz = false;
      setMyStatus(2);
      setComputerStatus(1);
      setComputerAnswer(answer);
      setComputerScore(computerScore + 100);
      audioRef.current.src = wrongAnswerSound;

      timeOut = 4100;
    }
    updateResultToOriginal();

    audioRef.current.play();
    setTimeout(() => {  setNowQuizNum(0);
      setMyStatus(0);
      setComputerStatus(0);
      setMyAnswer("");
      setComputerAnswer("");
      setMyTurn(false);
      setComputerTurn(false);
      setIsAnswerSelected(false);
      setTimer(6);
      myTurnCounter = 6;
    }, timeOut);
  }


  useEffect(() => {
    if(nowQuizNum === 0){
      if(computerScore === myScore){
        setWhoIsWinner(0);
      }else if(myScore > computerScore){
        setWhoIsWinner(1);
      }else if(computerScore > myScore){
        setWhoIsWinner(2);
      }
    }
  }, [nowQuizNum]);

  // 원본 제이슨 참조 변수에 결과 업데이트, 이후 정답 오답, 학습 진행 결과 업데이트에 사용
  const updateResultToOriginal = () =>{

    let isAllSolved = true;
    for (let i = 0; i < tempSortedJson.data.length; i++){

      for (let j = 0; j < originalJson.data.length; j++){

        if(tempSortedJson.data[i].answer === originalJson.data[j].answer){
          if(tempSortedJson.data[i].solvedQuiz !== undefined){
            originalJson.data[j].solvedQuiz = tempSortedJson.data[i].solvedQuiz;
          }else{
            isAllSolved = false;
          }
        }

      }

    }
    if(isAllSolved === true){
      setIsAllQuizSolved(true);

      async function callApiAct() {
        if (actInfo.actStatus !== 'Completed') {
          const data = await API('post', 'act', {}, {
            oCode: userSelector.info.oCode,
            productId: userSelector.info.productId,
            studentId: userSelector.info.studentId,
            courseId: userSelector.info.courseId,
            bookId: userSelector.info.bookId,
            level: actInfo.level,
            episodeId: episodeInfo.episodeId,
            episodeCode: episodeInfo.episodeCode,
            lessonId: lessonInfo.lessonId,
            actId: actInfo.actId,
            actType: actInfo.actType,
            actStatus: "Completed",
            actPoint: actInfo.actCompletPoint,
            actCurrentPage: 1,
            question: JSON.stringify(tempSortedJson),
            answer: JSON.stringify(tempSortedJson)
          }).catch((error) => {
            console.error(error);
          });

          console.log(data);

          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .actStatus = "Completed";
          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .file = actInfo.actName + ".json";
          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .fileUrl = originalJson;
          userSelector.info
              .episodes.find(item => item.episodeId === location.state.episodeId)
              .lessons.find(item => item.lessonId === location.state.lessonId)
              .acts.find(item => item.actId === location.state.actId)
              .player = "";

          dispatch(userStore.save(userSelector.info));
        }

      }
      callApiAct();

    }
  }

  const retryAllQuizs = () =>{

    for (let i = 0; i < tempSortedJson.data.length; i++){

      tempSortedJson.data[i].solvedQuiz = undefined;

    }

    setMyScore(0);
    setComputerScore(0);
    setWhoIsWinner(0);
    setIsAllQuizSolved(false);
    setNowQuizNum(0);
  }

  // 가이드 애니메이션 카운터
  let guideCounter = 5;

  let guideRepeater = true;

  const clickGuide = useRef();

  const guideTimer = () => {
    if(nowQuizNum !==0){
      return
    }

    if(nowQuizNum === 0 && guideRepeater === true){
      let noChoiceInterval = setInterval(function(){
        guideCounter--;
        if(guideCounter === 0 ){



          clearInterval(noChoiceInterval);
        }
        if(nowQuizNum === 0 && guideCounter ===0){

          try {
            clickGuide.current.style.display = 'block';
          }catch (error){

          }
          guideRepeater = false;

          // 문제 선택중이면 try, 문제 선택해서 ref Dom 없는상태면 catch 에서 아무것도 안함
          setTimeout(() => {

            try {
              clickGuide.current.style.display = 'none';
            }catch (error){

            }

          }, 2600);

          setTimeout(() => {
            guideCounter = 5;
            guideRepeater = true;
            guideTimer();
          }, 5000);

        }

      }, 1000);
    }
  }

  return (
    <div className="word-play">
      {isPopUp === true ?
          <div style={{zIndex:"9", width:"100%", height:"100%", backgroundColor:"rgba(1,1,1,0.7)", position:"absolute"}}>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              <img style={{width:"90%",height:"100vh"}} src={popImg[episodeInfo.episodeNum-1]}/>
              <img style={{zIndex:"10", position:"absolute",bottom:"25px",cursor:"pointer"}} src={PopStart} onClick={() => onClickIsPopUpSet()}/>
            </div>
            {/*<div onClick={() => onClickIsPopUpSet()}>*/}
            {/*  닫기*/}
            {/*</div>*/}
          </div>
          :
          ""
      }

      <CloseActivityModal ref={closeActivityModalRef} />
      <div>
        <div className="contents d-flex flex-column justify-content-between">
          <div className="route_bar d-flex align-items-center justify-content-between">
            {/*<div className="right_icon" onClick={()=> onClickIsPopUpSet()} >*/}
            {/*  팝업 열기*/}
            {/*</div>*/}
            <div className="right_icon">
              <img className="help_icon_img" src={HelpIcon} alt="" onClick={()=> onClickIsPopUpSet()} />
              <img className="cancel_icon_img" src={CancelIcon} alt="" onClick={() => handleClose()} />
            </div>
          </div>
        </div>
      </div>
      <div className="top-light">
        <span className="light left"></span>
        <span className="light right"></span>
      </div>
      <div className="box">
        <div className="billboard">{/*전광판*/}
          <span className="billboard-bar left"></span>
          <span className="billboard-bar right"></span>
          <span className="billboard-line"></span>
          <div className="billboard-wrap">
            <div className="center-light">
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
              <span className="light"><img src={CenterLigth} alt="" /></span>
            </div>
            <p className="word-contest">Word Contest</p>

            <div className="billboard-box">

              {/*최초 렌더링이거나, 정답 제출시 nowQuizNum을 0으로 초기화, 셋팅 하고 makeQuizNumberDom 메소드 호출
              메소드로 chooseQuiz 메소드가 바인딩된 jsx DOM를 생성, 해당 DOM (퀴즈) 선택시 번호가 바뀌면 chosenQuiz 메소드 호출, 선택된 퀴즈번호 오브젝트에서 데이터 가공후 jsx DOM 리턴
              리턴된 정답 보기 DOM 2개 에는 submitAnswer 메소드가 해당 퀴즈객체의 정보와 바인딩 되어있어 클릭시 정답 제출인지 오답 제출인지 체크해주고 다시 nowQuizNum을 0으로 셋팅하여 문제 선택 씬으로 순환
              */}
              {nowQuizNum === 0 ?
                  <div className="word-select inner" style={{'display':'inline-block'}}>
                    <p className="question">Choose a box below.</p>
                    <div className="num-line">

                      {isAllQuizSolved === false ?
                          makeQuizNumberDom( tempSortedJson)
                          :
                          <>
                            {makeQuizNumberDom( tempSortedJson)}
                            <div className="modal_width result_word" style={{'display':'block'}}>
                              <div className="modal_overlay"></div>
                              <div className="modal_wrapper wordconstest_modal_override">
                                <div className="top">
                                  <img className="winner_img" src={whoIsWinner === 0 ? draw : whoIsWinner === 1 ? avatarInfo[studentAvatarNo].win : avatarInfo[studentAvatarNo].lose} alt="" />
                                  {/*
                                  <p className="winner">{whoIsWinner === 0 ? "Draw" : whoIsWinner === 1 ? userSelector.info.studentNickName : avatarInfo[tempComputerAvaterNo].name }</p>
                                  */}
                                  {/*승자 이름*/}
                                </div>
                                <div className="buttongroup img-btn">
                                  <span><img src={PopReply} alt="" onClick={() => retryAllQuizs()}/></span>
                                  <span><img src={PopHome} alt="" onClick={() => history.goBack()}/></span>
                                </div>
                              </div>
                            </div>
                          </>

                      }

                    </div>
                  </div>
                  :
                  chosenQuiz(tempSortedJson.data[nowQuizNum -1])
              }

            </div>
          </div>
        </div>
      </div>

      {/*단상*/}
      <div className="score-line">

        {/*캐릭터 있는 왼쪽 단상*/}
        <div className="score-box left">
          {/*캐릭터*/}
          <div className="character">
            {/*승자일경우 추가되는 부분*/}
            <div className="particle">

            </div>
            {/*승자일경우 추가되는 부분*/}
            {/*문제풀이 결과 correct, oops*/}

            <div className="answer-result">
              <></>
            </div>

            {/*문제풀이 결과 correct, oops*/}
            {/*캐릭터 들어가는 태그 - 캐릭터 총 7명*/}
            <span>
              <img src={avatarInfo[tempComputerAvaterNo].img} alt="" />
            </span>
            {/*캐릭터 들어가는 태그 - 캐릭터 총 7명*/}
          </div>
          {/*캐릭터*/}

          {/*단상 내용*/}
          <div className="score-table">
            {/* 공백문자 없으면 스타일 깨집니다.*/}
            <span className="character-name">{avatarInfo[tempComputerAvaterNo].name}</span>{/*이름*/}
            <span className="score-num">{computerScore}</span>{/*점수*/}
            <div className="btm">{/*상태 3가지 1. 부스 배경 변경 2. 카운트(1,2,3) 3. 정답단어노출 4.결과화면에서 승자 트로피 표시*/}
              {/*1. 부스 배경 변경*/}
              <div className="bootharea">
                {computerTurn === true && computerStatus === 1 ? <img src={BoothOn} alt="" /> : <img src={Booth} alt="" />}
              </div>

              {/*3. 정답단어노출*/}
              <span className="answer-word"></span>
              {/*4.결과화면에서 승자 트로피 표시*/}

              {whoIsWinner === 2 && nowQuizNum === 0 ? <span className="thropy">
                <img src={thropy} alt="" />
              </span> : ""}

            </div>
          </div>
          {/*단상 내용*/}
        </div>
        {/*캐릭터 있는 왼쪽 단상*/}

        {/*유저 캐릭터 있는 오른쪽 단상*/}
        <div className="score-box right">
          {/*캐릭터*/}
          <div className="character">
            {/*승자일경우 추가되는 부분*/}
            <div className="particle">
              {myStatus === 1 || (whoIsWinner === 1 && nowQuizNum === 0)? <img src={Particle} alt="" />
                  : ""}
            </div>
            {/*승자일경우 추가되는 부분*/}
            {/*문제풀이 결과 correct, oops*/}

            <div className="answer-result">

              {myStatus === 1?
                  <img className="correct-img" src={resultCorrect} alt="" />
                  : myStatus === 2? <img className="oops-img" src={resultOops} alt="" /> : ""}
              {/* <img className="correct-img" src={resultCorrect} alt="" /> */}

            </div>

            <span>
              <img src={avatarInfo[studentAvatarNo].img} alt="" />
            </span>

          </div>
          {/*캐릭터*/}

          {/*단상 내용*/}
          <div className="score-table">
            <span className="character-name">{userSelector.info.studentNickName}</span>{/*이름*/}
            <span className="score-num">{myScore}</span>{/*점수*/}
            <div className="btm">{/*상태 3가지 1. 부스 배경 변경 2. 카운트(1,2,3) 3. 정답단어노출 4.결과화면에서 승자 트로피 표시*/}
              {/*1. 부스 배경 변경*/}
              <div className="bootharea">
                {myTurn === true && computerTurn === false && myAnswer !== "" ? <img src={BoothOn} alt="" /> : <img src={Booth} alt="" />}

              </div>

              {/*2. 카운트(1,2,3)*/}
              <span className="count">
                {myAnswer === "" && myTurn === true && timer < 4 ? <img src={countNumbers[timer]} alt="" /> : "" }

              </span>
              {/*3. 정답단어노출*/}
              <span className="answer-word">

              </span>
              {/*4.결과화면에서 승자 트로피 표시*/}
              {whoIsWinner === 1 && nowQuizNum === 0 ? <span className="thropy">
                <img src={thropy} alt="" />
              </span>: ""}

            </div>
          </div>
          {/*단상 내용*/}
        </div>
        {/*유저 캐릭터 있는 오른쪽 단상*/}

      </div>
      {/*단상*/}
      <audio ref={audioRef}></audio>
    </div>

  );


};



export default Index;