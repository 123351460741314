import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import videojs from 'video.js';
import '../../css/video-js.css'

const VideoJsPlayer = (props) => {
  const isFullscreen = useRef(false);
  const videoElem = useRef();
  const {filePath} = props;
  const videoRef = useRef(null);
  const playerRef = useRef(null);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: filePath,
            type: 'video/mp4'
        }]
    };


  console.log('options.sources: ',videoJsOptions.sources);

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = document.createElement('video-js');

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = (playerRef.current = videojs(videoElement, videoJsOptions, () => {
                console.log('player is ready');
                handlePlayerReady && handlePlayerReady(player);
            }));

        } else {
            const player = playerRef.current;

            player.autoplay(videoJsOptions.autoplay);
            player.src(videoJsOptions.sources);
        }
    }, []);

    // 컴포넌트 언마운트 시 플레이어 삭제
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);


    const handlePlayerReady = (player) => {
        playerRef.current = player;

        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('ended', () => {
            console.log('player is end');
            if (props.setNextButtonEnabled) {
                props.setNextButtonEnabled();
            }
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };


  return (
      <div data-vjs-player>
          <div ref={videoRef} disablePictureInPicture={true}/>
      </div>
  );
};

export default VideoJsPlayer;